import BaseButton from '@components/basebutton';
export const bootstrapCoursematrix = () => {
    const coursematrixElem = document.querySelector('.coursematrix');
    if (coursematrixElem) {
        const toggleDetailsElems = coursematrixElem.querySelectorAll('[data-action="toggle-details"]');
        const toggleProgressElems = coursematrixElem.querySelectorAll('[data-action="toggle-progress"]');
        if (toggleDetailsElems) {
            const toggleDetailsClassName = 'block--details-locked';
            Array.prototype.forEach.call(toggleDetailsElems, (elem) => {
                const scopeElem = elem.closest('.category-block, .coursematrix');
                const categoryBlockElems = scopeElem.classList.contains('category-block') ? [scopeElem] : Array.from(scopeElem.querySelectorAll('.category-block'));
                if (categoryBlockElems === null || categoryBlockElems === void 0 ? void 0 : categoryBlockElems.length) {
                    const actionName = `matrix-toggle-details`;
                    const toggleDetailsButton = new BaseButton(elem, actionName, {
                        defaultStatus: 'released',
                        title: 'Details anzeigen ein/aus',
                        actionPressed: function (e) {
                            for (const categoryBlockElem of categoryBlockElems) {
                                categoryBlockElem.classList.add(toggleDetailsClassName);
                            }
                        },
                        actionReleased: function (e) {
                            for (const categoryBlockElem of categoryBlockElems) {
                                categoryBlockElem.classList.remove(toggleDetailsClassName);
                            }
                        }
                    });
                }
            });
        }
        if (toggleProgressElems) {
            const toggleProgressClassName = 'block--progress-locked';
            Array.prototype.forEach.call(toggleProgressElems, (elem) => {
                const scopeElem = elem.closest('.category-block, .coursematrix');
                const categoryBlockElems = scopeElem.classList.contains('category-block') ? [scopeElem] : Array.from(scopeElem.querySelectorAll('.category-block'));
                if (categoryBlockElems === null || categoryBlockElems === void 0 ? void 0 : categoryBlockElems.length) {
                    const actionName = `matrix-toggle-progress`;
                    const toggleProgressButton = new BaseButton(elem, actionName, {
                        defaultStatus: 'released',
                        title: 'Fortschritt der Lerneinheiten anzeigen ein/aus',
                        actionPressed: function (e) {
                            for (const categoryBlockElem of categoryBlockElems) {
                                categoryBlockElem.classList.add(toggleProgressClassName);
                            }
                        },
                        actionReleased: function (e) {
                            for (const categoryBlockElem of categoryBlockElems) {
                                categoryBlockElem.classList.remove(toggleProgressClassName);
                            }
                        }
                    });
                }
            });
        }
    }
};
